@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "strawford";
  src: url("./fonts/strawford-bold-webfont.eot");
  src: url("./fonts/strawford-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/strawford-bold-webfont.woff2") format("woff2"),
    url("./fonts/strawford-bold-webfont.woff") format("woff"),
    url("./fonts/strawford-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "strawford";
  src: url("./fonts/strawford-light-webfont.eot");
  src: url("./fonts/strawford-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/strawford-light-webfont.woff2") format("woff2"),
    url("./fonts/strawford-light-webfont.woff") format("woff"),
    url("./fonts/strawford-light-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "strawford";
  src: url("./fonts/strawford-regular-webfont.eot");
  src: url("./fonts/strawford-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/strawford-regular-webfont.woff2") format("woff2"),
    url("./fonts/strawford-regular-webfont.woff") format("woff"),
    url("./fonts/strawford-regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Range slider - hide defaults */
input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none; /* Removes the blue border. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Range slider - thumb styles */
/* Special styling for WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #77db89;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background: #77db89;
  background-image: url("./img/double-sided-arrow.svg");
  background-size: cover;
  cursor: pointer;
  margin-top: -10px;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  border: 1px solid #77db89;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background: #77db89;
  background-image: url("./img/double-sided-arrow.svg");
  background-size: cover;
  cursor: pointer;
  margin-top: -10px;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  border: 1px solid #77db89;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background: #77db89;
  background-image: url("./img/double-sided-arrow.svg");
  background-size: cover;
  cursor: pointer;
  margin-top: -10px;
}

/* Range slider - track styles */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #77db89;
  border-radius: 1.3px;
  border: 0.2px solid #77db89;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #77db89;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: #77db89;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #77db89;
  border: 0.2px solid #010101;
  border-radius: 2.6px;

}
input[type="range"]:focus::-ms-fill-lower {
  background: #77db89;
}
input[type="range"]::-ms-fill-upper {
  background: #77db89;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #77db89;
}
